import './BigNumberWidget.css'

import React from 'react';

const Bignumberwidget = ({ number, headline, subhead }) => {
    return (
        <div>
            <div className='big-number-widget widget'>
                <p className='big-number'>{number}</p>
                <p className='headline'>{headline}</p>
                <p className='subhead'>{subhead}</p>
            </div>
        </div>
    );
}

export default Bignumberwidget;
