import './TasksComponent.css'
import React, { useEffect, useState, useRef } from 'react';
import { firestore } from '../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import { useAuth } from '../../../contexts/AuthContext';
import { ACTIONS, projectOperations } from '../../../DatabaseOperations';
import Todo from '../../Todo/TodoComponent/Todo';
import Projectinfocomponent from '../../Projects/ProjectInfoComponent/ProjectInfoComponent';
import Projectsdashboard from '../../Projects/ProjectsDashboard/ProjectsDashboard';

const Taskscomponent = () => {

    const [projects, setProjects] = useState([]);
    const [highlightedProjects, setHighlightedProjects] = useState([]);
    const [openProject, setOpenProject] = useState();
    const [openTab, setOpenTab] = useState('info');
    const { currentUser } = useAuth()

    

    const newProjectRef = useRef()

    //get projects
    useEffect(() => {
        const unsubscribeProjects = onSnapshot(firestore.collection('Projects')
        //.where('state', '==', 'active')
            , (querySnapshot) => {

                /* setTest(querySnapshot.data().text)
                console.log(querySnapshot.data().test) */
                const projectsArray = []
                querySnapshot.forEach((doc) => {
                    projectsArray.push(doc)
                    
                })
                setProjects(projectsArray)
                
                

            })
        return () => {
            unsubscribeProjects()
        };
    }, []);

    // Check for projects with open tasks or new tasks. holy shit just do it by time created.
    // okay that brings other difficulty. saving up who looked into it when? could be project data.

    // but lets start with the active tasks
    useEffect(() => {
        const unsubscribeTasks = onSnapshot(firestore.collectionGroup('Tasks').where('owner', '==', currentUser.displayName)
        //.where('active', '==', true)
        , (querySnapshot) => {

            /* setTest(querySnapshot.data().text)
            console.log(querySnapshot.data().test) */
            const highlightedProjectsArray = []
            querySnapshot.forEach((doc) => {
                highlightedProjectsArray.push(doc.data().project)
                console.log('highlightedProjectsArray')
                
                
            })
            setHighlightedProjects([...new Set(highlightedProjectsArray)])
            console.log([...new Set(highlightedProjectsArray)])
            

        })
        return () => {
            unsubscribeTasks()
        };
    }, []);

    // query all tasks subcollections for my tasks that are active
    // loop trough them and store the project names in an array
    // in the html make a conditional css class 'highlighted' or something when name is in array

    function handleProjectClick(project) {
        if (project == openProject) {
            setOpenProject()
            //hide tabs
        } else {
            setOpenProject(project)
            
        }
            
            //show tabs
            //hide other projects
        
    }

    function handleTabSwitch(name) {
        setOpenTab(name)

    }

    function addProject(e) {
        e.preventDefault()

        const name = newProjectRef.current.value        

        const data = {
            start: new Date(),
            state: 'active',
            type: 'Website',
            milestones: {},
            name: name

        }
        
        // Problem: need the doc id back to set it as active doc
        // Solution: make the call directly instead of over the 'dao'

        firestore.collection('Projects').add(data).then((docRef) => {
            firestore.collection('Projects').doc(docRef.id).get().then((doc) => {                
                handleProjectClick(doc)
            })
        })

        newProjectRef.current.value = ''

    }




    return (
        <div>
            <div className='controls'>
                <div className='project-buttons'>
                    {!openProject && projects.map(project => {
                        return <p key={project.data().name} className={`button-tab ${(highlightedProjects.includes(project.data().name)) ? "highlighted" : "not-highlighted"}`} onClick={() => handleProjectClick(project)}>{project.data().name}</p>
                    })}
                    {!openProject && <form onSubmit={addProject}><input className='add-project-input' type="text" ref={newProjectRef} placeholder='+ Add project' /></form>} 
                    {openProject && <p className={`button-tab active`} onClick={() => handleProjectClick(openProject)}>{openProject.data().name}</p>}
                </div>
                {openProject && <div className='project-tabs'>
                    <p className={`button-tab ${(openTab == 'info') ? "active" : ""}`} onClick={() => handleTabSwitch('info')}>Info</p>
                    <p className={`button-tab ${(openTab == 'chat') ? "active" : ""}`} onClick={() => handleTabSwitch('chat')}>Chat</p>
                    <p className={`button-tab ${(openTab == 'tasks') ? "active" : ""}`} onClick={() => handleTabSwitch('tasks')}>Tasks</p>
                    <p className={`button-tab ${(openTab == 'timeline') ? "active" : ""}`} onClick={() => handleTabSwitch('timeline')}>Timeline</p>
                </div>}
                
            </div>
            {openProject && <div className='tab-window'>
                {(openTab == 'info') && <Projectinfocomponent project={openProject}></Projectinfocomponent>}
                {(openTab == 'chat') && <p>Chat Component</p>}
                {(openTab == 'tasks') &&  <Todo project={openProject}></Todo>} 
                {(openTab == 'timeline') && <p>Timeline Component</p>}
            </div>}

            {!openProject && <Projectsdashboard projects={projects}></Projectsdashboard>}

        </div>
    );
}

export default Taskscomponent;
