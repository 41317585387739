import React from 'react'
import deleteIcon from './Delete.svg'
import './Step.css'
import { ACTIONS, stepOperations } from '../../../DatabaseOperations'
import checked from '../../../Icons/Checked.svg'
import unchecked from '../../../Icons/Unchecked.svg'

const Step = ({ step }) => {
    return (
        <div className={`step finished-${step.data().completed}`}>
            <div>

                <img className='step-toggle-checked' src={step.data().completed ? checked : unchecked} onClick={() => stepOperations(ACTIONS.TOGGLE_STEP, {}, step)} alt="" />
                <p className='step-text'>{step.data().text}</p>
            </div>
            <img className='delete-step-btn' src={deleteIcon} alt="" onClick={() => stepOperations(ACTIONS.DELETE_STEP, {}, step)} />
        </div>
    );
}

export default Step;
