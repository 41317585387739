import React, { useState, useEffect, useRef } from 'react';
import { ACTIONS, todoOperations } from '../../../DatabaseOperations';
import './TasksWithoutOwnerWidget.css'
import { firestore } from '../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import Task from '../../Todo/TaskComponent/Task';

const Taskswithoutownerwidget = () => {

    const taskTextRef = useRef()
    

    const [tasks, settasks] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(firestore.collection('Todo').where('owner', '==', 'none')
        .where('completed', '==', false), (querySnapshot) => {

            /* setTest(querySnapshot.data().text)
            console.log(querySnapshot.data().test) */
            const tasksArray = []
            querySnapshot.forEach((doc) => {
                tasksArray.push(doc)
            })
            settasks(tasksArray)

        })
        return () => {
            unsubscribe()
        };
    }, []);



    function addTask(e) {
        e.preventDefault()
        //get value of field
        const task = taskTextRef.current.value        

        const data = {
            text: task,
            completed: false,
            owner: 'none'
        }        

        todoOperations(ACTIONS.ADD_TODO, "", data)
        taskTextRef.current.value = ""
    }

    return (
        <div className='widget tasks-no-owner'>
            <div className='widget-head'>

                <p className='headline'>{tasks.length} Tasks without owner</p>

                <form className='add-task-form' onSubmit={addTask}>

                    <input className='task-input' ref={taskTextRef} placeholder='+ Add task' type="text" />
                </form>
            </div>
            <div>
                {tasks.map(task => {
                    return <Task key={task.id} task={task}></Task>
                })}
            </div>
        </div>
    );
}

export default Taskswithoutownerwidget;
