import React, { useState, useEffect } from 'react';
import './ClientsTab.css'
import { firestore } from '../../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import mailIcon from '../../../../Icons/mail.svg'
import phoneIcon from '../../../../Icons/phone.svg'
import addIcon from '../../../../Icons/add.svg'
import Clientinfocomponent from '../../ClientInfoComponent/ClientInfoComponent';

const Clientstab = () => {

    const [openClient, setOpenClient] = useState();
    const [clients, setClients] = useState([]);

    // useEffect get clients
    useEffect(() => {
        const unsubscribeClients = onSnapshot(firestore.collection('Clients').limit(25)
            , (querySnapshot) => {

                const clientsArray = []
                querySnapshot.forEach((doc) => {
                    clientsArray.push(doc)
                    //console.log(doc.data().name)

                })
                setClients(clientsArray)
                //console.log(clientsArray)



            })
        return () => {
            unsubscribeClients()
        };
    }, []);

    function copyToClipboard(text) {

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(text);

        /* Alert the copied text */
        alert('Copied to clipboard');
    }

    function switchOpenClient(client) {
        setOpenClient(client)
    }

    function toggleOpenClient() {
        setOpenClient()
    }

    function addClient() {

        const data = {
            name: 'New Client',
            location: {}
        }

        firestore.collection('Clients').add(data).then((docRef) => {
            firestore.collection('Clients').doc(docRef.id).get().then((doc) => {                
                switchOpenClient(doc)
            })
        })
    }

    // Toggle functionality single view list view

    return (
        <div>
            {openClient && <p className={`button-tab active client-btn`} onClick={() => toggleOpenClient()}>{openClient.data().name}</p>}
            {!openClient && <div className='client-list'>
                <div className='client-div'>
                    <p>Name</p>
                    <p>City</p>
                    <p>Industry</p>
                    <p>Contact</p>
                    <img src={mailIcon} alt="mail" />
                    <img src={addIcon} className='add-btn' alt="add" onClick={() => addClient()}/>
                </div>
                {clients.map(client => {
                    return <div className='client-div client-div-full' onClick={() => switchOpenClient(client)}>
                        <p>{client.data().name}</p>
                        <p>{client.data().location.city}</p>
                        <p>{client.data().industry}</p>
                        <p>{client.data().contact}</p>
                        <img src={mailIcon} alt="mail" onClick={() => copyToClipboard(client.data().email)} />
                        <img src={phoneIcon} alt="phone" onClick={() => copyToClipboard(client.data().phone)} />
                    </div>
                })}
            </div>}
            {openClient && <Clientinfocomponent client={openClient}></Clientinfocomponent>}
        </div>
    );
}

export default Clientstab;
