import './App.css';
import Todo from './Components/Todo/TodoComponent/Todo'
import Dashboard from './Components/Dashboard/Dashboard';
import Logincomponent from './Components/LoginComponent/LoginComponent';
import Signupcomponent from './Components/Signup/SignupComponent';
import Sidebar from './Components/Sidebar/Sidebar';
import Projectscomponent from './Components/Projects/ProjectsComponent/ProjectsComponent';
import Taskscomponent from './Components/Tasks/TasksComponent/TasksComponent';
import Clientsoverviewcomponent from './Components/Clients/ClientsOverview/ClientsOverviewComponent/ClientsOverviewComponent';
import AuthProvider from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';
import { updateProfile } from 'firebase/auth'
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";


function App() {

	return (
		<div className="App">
			<BrowserRouter>
				<AuthProvider>
					<Sidebar></Sidebar>
					<div className='app-content'>
						<Routes>
						<Route path='/signup' element={<Signupcomponent></Signupcomponent>}></Route>
						<Route path='/login' element={<Logincomponent></Logincomponent>}></Route>
						<Route path='/' element={<RequireAuth redirectTo={'/login'}><Dashboard></Dashboard></RequireAuth>}></Route>
						<Route path='/todo' element={<RequireAuth redirectTo={'/login'}><Todo></Todo></RequireAuth>}></Route>
						<Route path='/projects' element={<RequireAuth redirectTo={'/login'}><Projectscomponent></Projectscomponent></RequireAuth>}></Route>
						<Route path='/tasks' element={<RequireAuth redirectTo={'/login'}><Taskscomponent></Taskscomponent></RequireAuth>}></Route>
						<Route path='/clients' element={<RequireAuth redirectTo={'/login'}><Clientsoverviewcomponent></Clientsoverviewcomponent></RequireAuth>}></Route>
					</Routes>
					</div>
					
				</AuthProvider>
			</BrowserRouter>
		</div>
	);
}

function RequireAuth({ children, redirectTo }) {
	const { currentUser } = useAuth()

	// Temporary code until all Names are saved. 
	try {

		if (!currentUser.displayName) {
			//console.log('name change')
			const name = currentUser.email.split('@')[0]
			const nameCap = name.charAt(0).toUpperCase() + name.slice(1)
			updateProfile(currentUser, { displayName: nameCap })

		}


	} catch {
		//console.log('error caught')
	}




	return currentUser ? children : <Navigate to={redirectTo} />;

}

export default App;
