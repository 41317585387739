
import './LoginComponent.css'
import React, { useRef, useState } from 'react'

import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

const Logincomponent = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()


        try {
            setError('')
            setLoading(true)
           await login(emailRef.current.value, passwordRef.current.value)
           navigate("/")
        } catch {
            setError('failed to log in')
            
        }
        setLoading(false)
    }

    return (
        <div className='Login'>
            <h1>Anmelden</h1>
                {error && <h2>{error}</h2>}

            <form onSubmit={handleSubmit} action="">
                {/* <label htmlFor="email">Email</label> */}
                <input className="input-field" type="email" ref={emailRef} placeholder="Email" />

                {/* <label htmlFor="password">Password</label> */}
                <input className="input-field" id='password' type="password" ref={passwordRef} placeholder="Passwort" />

                <input disabled={loading} type='submit' text='Anmelden' ></input>
                
            </form>

        </div>
    )
}

export default Logincomponent;
