import './ProjectsComponent.css'
import { firestore } from '../../../firebase/firebase'

import { onSnapshot } from 'firebase/firestore'

import React, { useState, useEffect } from 'react';
import BigNumberWidget from '../../ReuseableWidgets/BigNumberWidget/BigNumberWidget';
import Potentialprojectswidget from '../Widgets/PotentialProjectsWidget/PotentialProjectsWidget';


const Projectscomponent = () => {


    const [projects, setProjects] = useState([]);
    const [potProjects, setPotProjects] = useState([]);

    // get projects
    useEffect(() => {
        const unsubscribeProjects = onSnapshot(firestore.collection('Projects').where('state', '==', 'active')
        , (querySnapshot) => {

        /* setTest(querySnapshot.data().text)
        console.log(querySnapshot.data().test) */
        const projectsArray = []
        querySnapshot.forEach((doc) => {
            projectsArray.push(doc)
            console.log(doc.data())
        })
        setProjects(projectsArray)
        console.log(projectsArray)

    })

    const unsubscribePotProjects = onSnapshot(firestore.collection('Projects').where('state', '==', 'potential')
        , (querySnapshot) => {

        /* setTest(querySnapshot.data().text)
        console.log(querySnapshot.data().test) */
        const projectsArray = []
        querySnapshot.forEach((doc) => {
            projectsArray.push(doc)
            console.log(doc.data())
        })
        setPotProjects(projectsArray)
        console.log(projectsArray)

    })
        return () => {
            unsubscribeProjects()
            unsubscribePotProjects()
        };
    }, []);


    return (
        <div>
            <div className='widget-container'>
                <BigNumberWidget number={projects.length} headline={'Active projects'} subhead={'(You)'}></BigNumberWidget>
                <BigNumberWidget number={projects.length} headline={'Active projects'} subhead={'(Fourcode)'}></BigNumberWidget>
                <Potentialprojectswidget potProjects={potProjects}></Potentialprojectswidget>
            </div>
            {projects.map(project => {
                return <p>{project.data().name}</p>
            })}
            
        </div>
    );
}

export default Projectscomponent;
