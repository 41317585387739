import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import { getStorage, ref } from "firebase/storage";
//import "firebase/storage";
import { get } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqHJc0cEsR7NzWP02h4pbxc7026UvTW3o",
  authDomain: "dropflow-c9a7e.firebaseapp.com",
  projectId: "dropflow-c9a7e",
  storageBucket: "dropflow-c9a7e.appspot.com",
  messagingSenderId: "780121693310",
  appId: "1:780121693310:web:3a1961ec1b05b4b76b4c2f",
  measurementId: "G-C7N1J8WFWE"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth()
export const database = app.database()
export const destinations = firebase.database().ref('/destinations')

export const firestore = app.firestore()

//export const storage = app.storage();
export const storage = getStorage(app)

export const storageRef = ref(storage, 'Presentations')

export const reference = ref

export const getData = get

export default app