import React, { useState, useEffect, useRef } from 'react';
import './LeadsInfoComponent.css'
import editIcon from '../../../Icons/Edit.svg'
import closeIcon from '../../../Icons/Close.svg'
import { leadOperations, ACTIONS } from '../../../DatabaseOperations';

const Leadsinfocomponent = ({ lead }) => {


    const [firstcontact, setFirstContact] = useState();
    const [lastcontact, setLastContact] = useState();
    const [leadEditMode, setLeadEditMode] = useState();

    const nameRef = useRef()
    const contactRef = useRef()
    const cityRef = useRef()
    const adressRef = useRef()
    const zipcodeRef = useRef()
    const industryRef = useRef()
    const volumeRef = useRef()
    const firstcontactRef = useRef()
    const lastcontactRef = useRef()
    const initialcontactRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const usualchannelRef = useRef()
    const notesRef = useRef()

    // turn timestamps into dates
    useEffect(() => {
        try {
            setFirstContact(lead.data().firstcontact.toDate().toDateString())
            setLastContact(lead.data().lastcontact.toDate().toDateString())
        } catch {

        }

    }, [lead]);

    // toggle edit mode
    function toggleEditMode() {
        setLeadEditMode(!leadEditMode)
    }

    // submitchanges
    function handleSubmit(e) {
        e.preventDefault()

        //get values of form
        const data = {
            name: nameRef.current.value,
            contact: contactRef.current.value,
            location: {
                city: cityRef.current.value,
                adress: adressRef.current.value,
                zipcode: zipcodeRef.current.value,
            },
            industry: industryRef.current.value,
            volume: volumeRef.current.value,
            initialcontact: initialcontactRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            usualchannel: usualchannelRef.current.value,
            notes: notesRef.current.value
        }
        //edit
        leadOperations(ACTIONS.EDIT_LEAD, data, lead)

        toggleEditMode()
    }

    if (lead.data()) {
        return (
            <div className='lead-info widget'>
                <div>
                    <img className='project-edit-btn' src={editIcon} onClick={() => toggleEditMode()} alt="Edit" />
                    <h1 className='headline'>{lead.data().name}</h1>
                    <div className='info-fields'>
                        <div className='info-field'>
                            <p className='info-label'>Contact:</p>
                            <p>{lead.data().contact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>City:</p>
                            <p>{lead.data().location.zipcode} </p>
                            <p> {lead.data().location.city}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Adress:</p>
                            <p>{lead.data().location.adress}</p>

                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Industry:</p>
                            <p>{lead.data().industry}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Volume:</p>
                            <p>{lead.data().volume}</p>
                        </div>

                        <div className='info-field'>
                            <p className='info-label'>First contact:</p>
                            <p>{firstcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Last contact:</p>
                            <p>{lastcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Initial contact:</p>
                            <p>{lead.data().initialcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Email:</p>
                            <p>{lead.data().email}</p>
                        </div>

                        <div className='info-field'>
                            <p className='info-label'>Phone:</p>
                            <p>{lead.data().phone}</p>
                        </div>



                        <div className='info-field'>
                            <p className='info-label'>Usual channel:</p>
                            <p>{lead.data().usualchannel}</p>
                        </div>


                    </div>
                    <div className='notes'>
                        <p className='notes-headline'>Notes:</p>
                        <p className='notes-text'>{lead.data().notes}</p>
                    </div>
                </div>


                {leadEditMode && <div className='lead-edit-window'>
                    <img className='close-edit-window-btn' src={closeIcon} alt="delete" onClick={() => toggleEditMode()} />
                    <form onSubmit={handleSubmit}>
                        <div className='form-element'>
                            <p>Name</p>
                            <input defaultValue={lead.data().name} ref={nameRef} type="text" />
                        </div>
                        <div className='basic-form'>

                            <div className='form-element'>
                                <p>Contact</p>
                                <input defaultValue={lead.data().contact} ref={contactRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>City</p>
                                <input defaultValue={lead.data().location.city} ref={cityRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Adress</p>
                                <input defaultValue={lead.data().location.adress} ref={adressRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Zip code</p>
                                <input defaultValue={lead.data().location.zipcode} ref={zipcodeRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Email</p>
                                <input defaultValue={lead.data().email} ref={emailRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Phone</p>
                                <input defaultValue={lead.data().phone} ref={phoneRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Industry</p>
                                <input defaultValue={lead.data().industry} ref={industryRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Volume</p>
                                <input defaultValue={lead.data().volume} ref={volumeRef} type="number" />
                            </div>
                            <div className='form-element'>
                                <p>Intial contact</p>
                                <select ref={initialcontactRef} className='dropdown' defaultValue={lead.data().initialcontact}>
                                    <option value="active">Harvest</option>
                                    <option value="scheduled">Website</option>
                                    <option value="finished">Other</option>
                                </select>
                            </div>
                            <div className='form-element'>
                                <p>Usual channel</p>
                                <select ref={usualchannelRef} className='dropdown' defaultValue={lead.data().usualchannel}>
                                    <option value="website">Phone</option>
                                    <option value="software">Email</option>
                                    <option value="print">Other</option>
                                </select>
                            </div>

                        </div>

                        <div className='form-element notes'>
                            <p>Notes</p>
                            <input defaultValue={lead.data().notes} ref={notesRef} type="text" />
                        </div>

                        <button className='input-submit' name="name" value="value" type="submit">Save</button>

                    </form>

                </div>}

            </div>
        )
    }

    return (
        <div>

        </div>
    );
}

export default Leadsinfocomponent;
