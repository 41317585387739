import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'

import './TasksCompletedWidget.css'

const TasksCompletedWidget = ({ owner }) => {

    const [numberOfTasks, setNumberOfTasks] = useState(0);
    



    if (owner) {
        //console.log('wir haben einen owner: ' + owner)

    } else {
        //console.log('kein owner hier' + owner)
    }

    useEffect(() => {
        let unsubscribe
        const todaysDate = new Date()
        todaysDate.setHours(0, 0, 0, 0)
        
 
        if (owner) {
            unsubscribe = onSnapshot(firestore.collection('Todo').where('dateFinished', '>=', todaysDate)
                .where('owner', '==', owner)
                , (querySnapshot) => {

                    const tasksArray = []
                    querySnapshot.forEach((doc) => {
                        tasksArray.push(doc)
                    })
                    setNumberOfTasks(tasksArray.length)

                })
        } else {
            unsubscribe = onSnapshot(firestore.collection('Todo').where('dateFinished', '>=', todaysDate)
                , (querySnapshot) => {

                    const tasksArray = []
                    querySnapshot.forEach((doc) => {
                        tasksArray.push(doc)
                    })
                    setNumberOfTasks(tasksArray.length)

                })
        }

        return () => {
            unsubscribe()
        };
    }, []);
    //useEffect get number of tasks

    //later get number of tasks for specific owner. oh damn the login stuff. we coud do that today
    //yeah lets do that in the next focus block

    return (
        <div className='task-completed-widget widget'>
            <p className='big-number'>{numberOfTasks}</p>
            <p className='headline'>Tasks completed today</p>
            {owner === '' && <p className='subhead'>(Fourcode)</p>}
            {owner != '' && <p className='subhead'>(You)</p>}
        </div>
    );
}

export default TasksCompletedWidget;
