import React, { useRef, useState } from 'react'

import { useAuth } from '../../contexts/AuthContext'

import { Link, useNavigate } from 'react-router-dom'

const Signupcomponent = () => {
    
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const navigate = useNavigate()


    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        try {
            setError('')
            setLoading(true)
           await signup(emailRef.current.value, passwordRef.current.value)
           navigate("/")
        } catch(e) {
            console.log(e)
            setError('failed to create account')
            
        }
        setLoading(false)        
    }

    return (
        <div className='card'>
            <h2>Sign Up</h2>
                {error && <h2>{error}</h2>}

            <form onSubmit={handleSubmit} action="">
                <label htmlFor="email">Email</label>
                <input type="email" ref={emailRef} />

                <label htmlFor="password">Password</label>
                <input id='password' type="password" ref={passwordRef} />

                <label htmlFor="password-confirm">Confirm Password</label>
                <input id='password-confirm' type="password" ref={passwordConfirmRef} />
                <input disabled={loading} type='submit' text='Sign Up' ></input>
                
            </form>
            Already have an account?
            <Link to="/login" >Log In</Link>
            
        </div>
    )
}

export default Signupcomponent;
