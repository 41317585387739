import { firestore } from './firebase/firebase'

export const ACTIONS = {
    ADD_TODO: 'add-todo',
    EDIT_TODO: 'edit-todo',
    DELETE_TODO: 'delete-todo',

    ADD_STEP: 'add-step',
    DELETE_STEP: 'delete-step',
    EDIT_STEP: 'edit-step',
    TOGGLE_STEP: 'toggle-step',

    ADD_PROJECT: 'add-project',
    EDIT_PROJECT: 'edit-project',

    ADD_CLIENT: 'add-client',
    EDIT_CLIENT: 'edit-client',
    DELETE_CLIENT: 'delete-client',

    ADD_LEAD: 'add-lead',
    EDIT_LEAD: 'edit-lead',
    DELETE_LEAD: 'delete-lead'


}

export function todoOperations(action, data, task) {
    console.log('Todo Database Operation')
    //console.log('action: ' + action)
    switch (action) {
        case ACTIONS.ADD_TODO:
            
            firestore.collection(`Projects/${data.projectID}/Tasks`).add(data).catch(function(error) {
                console.log("Error adding document: ", error);
            });
            console.log('added')
            break

        case ACTIONS.EDIT_TODO:
            if (data.completed) {
                data.dateFinished = new Date()
            }
            firestore.collection(`Projects/${task.data().projectID}/Tasks`).doc(task.id).update(data).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('updated')
            //console.log(data)
            break

        case ACTIONS.DELETE_TODO:
            // oh damn oder wir machen das als cloud function.
            // we will do it as a cloud funciton for sure.
            firestore.collection(`Projects/${task.data().projectID}/Tasks`).doc(task.id).delete().catch(function(error) {
                console.log("Error deleting document: ", error);
            });
            console.log('deleted')
            break


        default:
            return
    }
}

export function stepOperations(action, data, step) {

    //console.log(data)
    switch (action) {

        case ACTIONS.ADD_STEP:
            
            firestore.collection(`Projects/${data.projectID}/Tasks/${data.taskID}/Steps`).add(data).catch(function(error) {
                console.log("Error adding document: ", error);
            });
            break

        case ACTIONS.DELETE_STEP:
            //console.log(firestore.collection(`Projects/${step.data().projectID}/Tasks/${step.data().taskID}/Steps`))
            firestore.collection(`Projects/${step.data().projectID}/Tasks/${step.data().taskID}/Steps`).doc(step.id).delete().catch(function(error) {
                console.log("Error deleting document: ", error);
            });
            break

        case ACTIONS.EDIT_STEP:
            firestore.collection(`Projects/${step.data().projectID}/Tasks/${step.data().taskID}/Steps`).doc(step.id).update(data).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('step edited')
            break

        case ACTIONS.TOGGLE_STEP:
            firestore.collection(`Projects/${step.data().projectID}/Tasks/${step.data().taskID}/Steps`).doc(step.id).update({ completed: !step.data().completed }).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('step edited')
            break

        default:
            return
    }
}

export function projectOperations(action, id, data) {
    console.log('Project DB Operation')
    console.log(action, id, data)

    switch (action) {
        case ACTIONS.ADD_PROJECT:

            const defaultData = {
                start: new Date(),
                state: 'active',
                type: 'Website',
                milestones: {},

            }

            const mergedData = Object.assign(defaultData, data)

            firestore.collection('Projects').add(mergedData).then((docRef) => {
                return docRef.id
            })
            console.log('added')
            break


        case ACTIONS.EDIT_PROJECT:
            firestore.collection('Projects').doc(id).update(data).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('updated')
            //console.log(data)
            break
        


    }

}

export function clientOperations(action, data, client) {
    console.log('Todo Database Operation')
    //console.log('action: ' + action)
    switch (action) {
        case ACTIONS.ADD_CLIENT:
            
            firestore.collection(`Clients`).add(data).catch(function(error) {
                console.log("Error adding document: ", error);
            });
            console.log('added')
            break

        case ACTIONS.EDIT_CLIENT:

            firestore.collection(`Clients`).doc(client.id).update(data).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('updated')
            //console.log(data)
            break

        case ACTIONS.DELETE_CLIENT:
            // oh damn oder wir machen das als cloud function.
            // we will do it as a cloud funciton for sure.
            firestore.collection(`Clients`).doc(client.id).delete().catch(function(error) {
                console.log("Error deleting document: ", error);
            });
            console.log('deleted')
            break


        default:
            return
    }
}

export function leadOperations(action, data, lead) {
    console.log('Lead Database Operation')
    //console.log('action: ' + action)
    switch (action) {
        case ACTIONS.ADD_LEAD:
            
            firestore.collection(`Leads`).add(data).catch(function(error) {
                console.log("Error adding document: ", error);
            });
            console.log('added')
            break

        case ACTIONS.EDIT_LEAD:

            firestore.collection(`Leads`).doc(lead.id).update(data).catch(function(error) {
                console.log("Error editing document: ", error);
            });
            console.log('updated')
            //console.log(data)
            break

        case ACTIONS.DELETE_LEAD:
            // oh damn oder wir machen das als cloud function.
            // we will do it as a cloud funciton for sure.
            firestore.collection(`Leads`).doc(lead.id).delete().catch(function(error) {
                console.log("Error deleting document: ", error);
            });
            console.log('deleted')
            break


        default:
            return
    }
}