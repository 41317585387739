import './Sidebar.css'
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className='sidebar shadow'>
            <Link className='link' to={'/Tasks'}>Tasks</Link>
            <Link className='link' to={'/Clients'}>Clients</Link>
            <Link className='link locked-link' to={'/projects'}>Finance</Link>
            <Link className='link locked-link' to={'/projects'}>Calendar</Link>
            <Link className='link locked-link' to={'/projects'}>Mail</Link>
            <Link className='link locked-link' to={'/projects'}>Drive</Link>
        </div>
    );
}

export default Sidebar;
