import React, { useEffect, useRef, useState } from 'react';
import { firestore } from '../../../firebase/firebase'
import { FirestoreError, onSnapshot } from 'firebase/firestore'
import { ACTIONS, projectOperations } from '../../../DatabaseOperations';
import ClientInfoComponent from '../../Clients/ClientInfoComponent/ClientInfoComponent';
import './ProjectInfoComponent.css'
import editIcon from '../../../Icons/Edit.svg'
import closeIcon from '../../../Icons/Close.svg'
import Clientsoverviewcomponent from '../../Clients/ClientsOverview/ClientsOverviewComponent/ClientsOverviewComponent';


const Projectinfocomponent = ({ project }) => {

    // useStates
    const [client, setClient] = useState();
    const [startDate, setstartDate] = useState();
    const [endDate, setendDate] = useState();

    const [clients, setClients] = useState([]);

    const [projectEditMode, setProjectEditMode] = useState(false);

    // alle Refs
    const projectEditFormRef = useRef()
    const projectNameRef = useRef()
    const projectStartRef = useRef()
    const projectEndRef = useRef()
    const projectStateRef = useRef()
    const projectTypeRef = useRef()
    const projectClientIdRef = useRef()
    const projectPriceRef = useRef()
    const projectCostRef = useRef()
    const projectNotesRef = useRef()

    // useEffect get client info
    useEffect(() => {
        
        firestore.collection(`Clients`).doc(project.data().clientid).get().then((doc) => {
            
            setClient(doc)
            
        })
        
    }, [project]);



    // turn timestamps into dates
    useEffect(() => {
        try {
            setstartDate(project.data().start.toDate().toDateString())
            setendDate(project.data().end.toDate().toDateString())
        } catch {

        }

    }, []);

    // submit project info changes
    function handleSubmit(e) {
        e.preventDefault()

        //get values of form
        const data = {
            name: projectNameRef.current.value,
            start: projectStartRef.current.value,
            end: projectEndRef.current.value,
            state: projectStateRef.current.value,
            type: projectTypeRef.current.value,
            price: parseInt(projectPriceRef.current.value),
            cost: parseInt(projectCostRef.current.value),
            notes: projectNotesRef.current.value,
        }

        if (clients.length) {
            data.clientid = projectClientIdRef.current.value            
        }
        //edit
        projectOperations(ACTIONS.EDIT_PROJECT, project.id, data)

        toggleEditMode()
    }


    // function submit changes

    function toggleEditMode() {

        //get steps
        //populate form with data

        setProjectEditMode(!projectEditMode)
        //console.log('toggled')
        //taskTextRef.current.value = task.data().text
    }


    // get list of clients for dropdown
    function handleClientMenuClick() {
        const clientsArray = []
        firestore.collection('Clients').limit(25).get().then((querysnapshot) => {
            querysnapshot.forEach(doc => {
                clientsArray.push(doc)
            });
            setClients(clientsArray)
        }
        )
    }


    return (
        <div className='info-tab'>
            <div className='project-info widget'>
                <img className='project-edit-btn' src={editIcon} onClick={() => toggleEditMode()} alt="Edit" />
                <h1 className='headline'>Project data</h1>
                <div className='info-fields'>
                    <div className='info-pair'>
                        <div className='info-field'>
                            <p className='info-label'>Start:</p>
                            <p>{startDate}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>End:</p>
                            <p>{endDate}</p>
                        </div>
                    </div>
                    <div className='info-pair'>
                        <div className='info-field'>
                            <p className='info-label'>State:</p>
                            <p>{project.data().state}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Type:</p>
                            <p>{project.data().type}</p>
                        </div>
                    </div>
                    <div className='info-pair'>
                        <div className='info-field'>
                            <p className='info-label'>Price:</p>
                            <p>{project.data().price}€</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Cost:</p>
                            <p>{project.data().cost}€</p>
                        </div>
                    </div>




                </div>
                <div className='notes'>
                    <p className='notes-headline'>Notes:</p>
                    <p className='notes-text'>{project.data().notes}</p>
                </div>

                {projectEditMode && <div className='project-info-edit-window'>
                    <img className='close-edit-window-btn' src={closeIcon} alt="delete" onClick={() => toggleEditMode()} />
                    <form ref={projectEditFormRef} onSubmit={handleSubmit}>
                        <div className='form-element'>
                            <p>Title</p>
                            <input defaultValue={project.data().name} ref={projectNameRef} type="text" />
                        </div>
                        <div className='basic-form'>

                            <div className='form-element'>
                                <p>Start</p>
                                <input defaultValue={project.data().start} ref={projectStartRef} type="date" />
                            </div>
                            <div className='form-element'>
                                <p>End</p>
                                <input defaultValue={project.data().end} ref={projectEndRef} type="date" />
                            </div>
                            <div className='form-element'>
                                <p>State</p>
                                <select ref={projectStateRef} className='dropdown' defaultValue={project.data().state}>
                                    <option value="active">Active</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="finished">Finished</option>
                                </select>
                            </div>
                            <div className='form-element'>
                                <p>Type</p>
                                <select ref={projectTypeRef} className='dropdown' defaultValue={project.data().type}>
                                    <option value="website">Website</option>
                                    <option value="software">Software</option>
                                    <option value="print">Print</option>
                                </select>
                            </div>
                            <div className='form-element'>
                                <p>Client</p>
                                <select ref={projectClientIdRef} className='dropdown' defaultValue={project.data().clientname} onClick={() => handleClientMenuClick()} >
                                    {clients.map(client => {
                                        
                                        return <option value={client.id}>{client.data().name}</option>
                                    })}
                                </select>
                            </div>
                            <div className='form-element'>
                                <p>Price</p>
                                <input defaultValue={project.data().price || 0} ref={projectPriceRef} type="number" />
                            </div>
                            <div className='form-element'>
                                <p>Cost</p>
                                <input defaultValue={project.data().cost || 0} ref={projectCostRef} type="number" />
                            </div>
                        </div>

                        <div className='form-element notes'>
                            <p>Notes</p>
                            <input defaultValue={project.data().notes} ref={projectNotesRef} type="text" />
                        </div>

                        <button className='input-submit' name="name" value="value" type="submit">Save</button>
                    </form>
                </div>}

            </div>

            {client && <ClientInfoComponent client={client}>abgehen</ClientInfoComponent>}
        </div>
    );
}

export default Projectinfocomponent;
