import React, { useEffect, useRef, useState } from 'react';
import checked from './Checked.svg'
import unchecked from './Unchecked.svg'
import closeIcon from './Delete.svg'
import deleteIcon from '../../../Icons/delete.svg'
import edit from './Edit.svg'
import { ACTIONS, stepOperations, todoOperations } from '../../../DatabaseOperations';
import './Task.css'
import { firestore } from '../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import Step from '../StepComponent/Step';




const Task = ({ task }) => {

    const taskTextRef = useRef()
    const taskProjectRef = useRef()
    const taskDeadlineRef = useRef()
    const taskOwnerRef = useRef()
    const taskStepRef = useRef()
    const editTaskWindowRef = useRef()

    const [steps, setSteps] = useState([]);
    const [editMode, seteditMode] = useState(false);
    const [extendedMode, setExtendedMode] = useState(false);

    /* function handleClick(e) {
        e.preventDefault()

        if (!editMode) {
            console.log('abgebrochen, weil ' + editMode)
            return
        }

        if (editTaskWindowRef.current.contains(e.target)) {
            console.log('click inside')
        } else {
            console.log('click outside')
            seteditMode(false)
        }
    } */

    /* useEffect(() => {


        window.addEventListener('click', handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
        };

    }, []);
 */
    useEffect(() => {



        let unsubscribe = () => {

        }

        if (editMode || extendedMode) {

            unsubscribe = onSnapshot(firestore.collection(`Projects/${task.data().projectID}/Tasks/${task.id}/Steps`), (querySnapshot) => {

                const stepsArray = []
                querySnapshot.forEach((doc) => {
                    stepsArray.push(doc)
                    //console.log(doc.data())
                })
                setSteps(stepsArray)
                //console.log(stepsArray)

            })
        }


        return () => {
            unsubscribe()

        };
    }, [editMode, extendedMode]);

    //funtion get steps of task. gets triggered when clicked or edited

    function handleSubmit(e) {
        e.preventDefault()

        //get values of form
        const data = {
            text: taskTextRef.current.value,
            project: taskProjectRef.current.value,
            owner: taskOwnerRef.current.value,
            deadline: taskDeadlineRef.current.value,
        }
        //edit
        todoOperations(ACTIONS.EDIT_TODO, data, task)

        toggleEditMode()
    }

    function toggleCompleted() {

        const data = {
            completed: !task.data().completed
        }

        todoOperations(ACTIONS.EDIT_TODO, data, task)

    }

    function addStep(e) {
        e.preventDefault()
        //get value of field
        const step = taskStepRef.current.value

        const data = {
            text: step,
            completed: false,
            taskID: task.id,
            projectID: task.data().projectID            
        }

        //add step to task subcollection
        stepOperations(ACTIONS.ADD_STEP, data, task)
        taskStepRef.current.value = ""
    }

    function deleteTask() {
        todoOperations(ACTIONS.DELETE_TODO, task.id, {})
    }

    function toggleEditMode() {

        //get steps
        //populate form with data

        seteditMode(!editMode)
        //console.log('toggled')
        //taskTextRef.current.value = task.data().text
    }

    function toggleExtendedMode() {
        setExtendedMode(!extendedMode)
    }

    return (
        <div className={`task finished-${task.data().completed}`} >
            <div className="task-base" onClick={() => toggleExtendedMode()}>
                <img className='task-toggle-checked' src={task.data().completed ? checked : unchecked} onClick={() => toggleCompleted()} alt="" />
                <div className='task-info'>
                    <p className='task-text'>{task.data().text}</p>
                    <p className='task-project'>{task.data().owner}</p>
                </div>
                <img className='task-edit-btn' src={edit} onClick={() => toggleEditMode()} alt="Edit" />
            </div>


            {extendedMode && <form className='steps-list' onSubmit={addStep}>

                {steps.map(step => {
                    return <div>
                        <Step key={step.id} step={step} taskID={task.id}></Step>
                    </div>
                })}
                <input className='step-input' ref={taskStepRef} placeholder='+ Add step' type="text" />

            </form>}

            {editMode && <div className='edit-task-window' ref={editTaskWindowRef}>
                <img className='close-edit-window-btn' src={closeIcon} alt="delete" onClick={() => toggleEditMode()} />
                {/* <img className='delete-task-btn' src={deleteIcon} alt="delete" onClick={() => deleteTask()} /> */}
                <form className='edit-form-basic' onSubmit={handleSubmit}>
                    <div className='form-element'>
                        <p>Text</p>
                        <input defaultValue={task.data().text} ref={taskTextRef} type="text" />
                    </div>
                    <div className='form-element'>
                        <p>Project</p>
                        <input defaultValue={task.data().project} ref={taskProjectRef} type="text" />
                    </div>
                    <div className='form-element'>
                        <p>Owner</p>
                        {/* <input defaultValue={task.data().owner} ref={taskOwnerRef} type="text" /> */}
                        <select ref={taskOwnerRef} className='owner-dropdown' defaultValue={task.data().owner}>
                            <option value="none">None</option>
                            <option value="Rene">Rene</option>
                            <option value="Tim">Tim</option>
                            <option value="Nick">Nick</option>
                            <option value="Simon">Simon</option>
                        </select>
                    </div>
                    <div className='form-element'>
                        <p>Deadline</p>
                        <input defaultValue={task.data().deadline} ref={taskDeadlineRef} type="date" />
                    </div>
                    <input className='input-submit' type="submit" />
                </form>
                <form onSubmit={addStep}>
                    <p className='steps-headline'>Steps</p>
                    {steps.map(step => {
                        return <div>
                            <Step key={step.id} step={step} taskID={task.id}></Step>
                        </div>
                    })}
                    <input className='step-input' ref={taskStepRef} placeholder='+ Add step' type="text" />

                </form>
            </div>}
        </div>
    );
}

export default Task;
