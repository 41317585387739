import React, { useState, useEffect } from 'react';
import './ProjectsDashboard.css'

const Projectsdashboard = ({ projects }) => {

    const [sumProfit, setsumProfit] = useState(0);
    const [sumRevenue, setsumRevenue] = useState(0);

    const [clientEditMode, setClientEditMode] = useState(false);

    // useEffect calculate project revenues
    useEffect(() => {
        let revenue = 0
        let cost = 0
        let profit = 0

        // loop through projects, add up price and cost
        projects.forEach((project) => {

            // to prevent a missing value from NaNing the number
            // not the most elegant solution but it works

            if (!isNaN(project.data().price)) {

                revenue += project.data().price
            }

            if (!isNaN(project.data().cost)) {

                cost += project.data().cost
            }


        })

        profit = revenue - cost
        setsumRevenue(revenue)
        setsumProfit(profit)

        console.log(revenue)

        var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        setsumProfit(formatter.format(profit)) /* $2,500.00 */
        setsumRevenue(formatter.format(revenue))

    }, [projects]);

    // useEffect get projects data for chart

    return (
        <div>
            <div className='widget project-finances'>
                <div className='active-projects'>                    
                    <p>{projects.length} active projects</p>
                </div>
                <div className='est-revenue'>
                    <p>Estimated revenue</p>
                    <p>{sumRevenue}</p>
                </div>
                <div className='est-profit'>
                    <p>Estimated profits</p>
                    <p>{sumProfit}</p>
                </div>

            </div>

        </div>
    );
}

export default Projectsdashboard;
