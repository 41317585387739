import './PotentialProjectsWidget.css'
import React from 'react';
import { Link } from 'react-router-dom';

const Potentialprojectswidget = ({ potProjects }) => {
    return (
        <div className='widget'>
            {potProjects.map(project => {
                return <Link to={`${project.id}`}>
                    <p>{project.data().name}</p>
                </Link>
            })}

        </div>
    );
}

export default Potentialprojectswidget;
