import React from 'react';
import TasksCompletedWidget from './TasksCompletedWidget/TasksCompletedWidget';
import Taskswithoutownerwidget from './TasksWithoutOwnerWidget/TasksWithoutOwnerWidget';
import { useAuth } from '../../contexts/AuthContext'

import './Dashboard.css'


const Dashboard = () => {

    
    const { logout } = useAuth()
    const { currentUser } = useAuth()
    return (
        <div>
            <p className='component-head'>Dashboard</p>
            <div className='component dashboard-container'>

                <TasksCompletedWidget className='widget tasks-completed-owner' owner={currentUser.displayName}></TasksCompletedWidget>
                <TasksCompletedWidget className='widget tasks-completed-team' owner={''}></TasksCompletedWidget>
                
                <Taskswithoutownerwidget className='tasks-no-owner'></Taskswithoutownerwidget>
                {/* <div className='tasks-no-owner'>

                </div> */}
                
                <p onClick={() => logout()}>Logout</p>

            </div>
        </div>
    );
}

export default Dashboard;
