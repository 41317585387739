import React, { useState, useEffect } from 'react';
import './LeadsTab.css'
import { firestore } from '../../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import mailIcon from '../../../../Icons/mail.svg'
import phoneIcon from '../../../../Icons/phone.svg'
import addIcon from '../../../../Icons/add.svg'
import Leadsinfocomponent from '../../LeadsInfoComponent/LeadsInfoComponent';

const Leadstab = () => {

    const [openLead, setOpenLead] = useState();
    const [leads, setLeads] = useState([]);


    // useEffect get leads
    useEffect(() => {
        const unsubscribeLeads = onSnapshot(firestore.collection('Leads').limit(25),
        (querysnapshot) => {
            const leadsArray = []
            querysnapshot.forEach((doc) => {
                leadsArray.push(doc)
            })
            setLeads(leadsArray)
        }
        )
        return () => {
            unsubscribeLeads()
        };
    }, []);

    function copyToClipboard(text) {

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(text);

        /* Alert the copied text */
        alert('Copied to clipboard');
    }

    function switchOpenLead(lead) {
        setOpenLead(lead)
    }

    function toggleOpenLead() {
        setOpenLead()
    }

    function addLead() {
        const data = {
            name: 'New Lead',
            location: {}
        }

        firestore.collection('Leads').add(data).then((docRef) => {
            firestore.collection('Leads').doc(docRef.id).get().then((doc) => {                
                switchOpenLead(doc)
            })
        })
    }

    return (
        <div>
            {openLead && <p className={`button-tab active lead-btn`} onClick={() => toggleOpenLead()}>{openLead.data().name}</p>}
            {!openLead && <div className='lead-list'>
                <div className='lead-div'>
                    <p>Name</p>
                    <p>City</p>
                    <p>Volume</p>
                    <p>Contact</p>
                    <img src={mailIcon} alt="mail" />
                    <img src={addIcon} alt="add" className='add-btn' onClick={() => addLead()}/>
                </div>
                {leads.map(lead => {
                    return <div className='lead-div lead-div-full' onClick={() => switchOpenLead(lead)}>
                        <p>{lead.data().name}</p>
                        <p>{lead.data().location.city}</p>
                        <p>{lead.data().volume}</p>
                        <p>{lead.data().contact}</p>
                        <img src={mailIcon} alt="mail" onClick={() => copyToClipboard(lead.data().email)} />
                        <img src={phoneIcon} alt="phone" onClick={() => copyToClipboard(lead.data().phone)} />
                    </div>
                })}
            </div>}
            {openLead && <Leadsinfocomponent lead={openLead}></Leadsinfocomponent>}
            
        </div>
    );
}

export default Leadstab;
