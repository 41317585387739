import React, { useState, useEffect } from 'react';
import './ClientsOverviewComponent.css'
import { firestore } from '../../../../firebase/firebase'
import { onSnapshot } from 'firebase/firestore'
import LeadsTab from '../LeadsTab/LeadsTab'
import ClientsTab from '../ClientsTab/ClientsTab'

const Clientsoverviewcomponent = () => {

    const [openTab, setOpenTab] = useState('clients');

    function handleTabSwitch(name) {
        setOpenTab(name)
    }


    return (
        <div>
            <div className='client-tabs'>
                <p className={`button-tab ${(openTab == 'clients') ? "active" : ""}`} onClick={() => handleTabSwitch('clients')}>Clients</p>
                <p className={`button-tab ${(openTab == 'leads') ? "active" : ""}`} onClick={() => handleTabSwitch('leads')}>Leads</p>
            </div>
            <div className='tab-window'>
                {(openTab == 'leads') && <LeadsTab></LeadsTab>}
                {(openTab == 'clients') && <ClientsTab></ClientsTab>}
            </div>
        </div>
    );
}

export default Clientsoverviewcomponent;
