import React, { useEffect, useRef, useState } from 'react';
import { firestore } from '../../../firebase/firebase'
import { useAuth } from '../../../contexts/AuthContext';
import { onSnapshot } from 'firebase/firestore'
import Task from '../TaskComponent/Task';
import { ACTIONS, todoOperations } from '../../../DatabaseOperations';
import './Todo.css'


const Todo = ({ project }) => {


    const [myTasks, setMyTasks] = useState([]);
    const [otherTasks, setOtherTasks] = useState([]);
    const taskTextRef = useRef()
    const taskCBRef = useRef()
    const { currentUser } = useAuth()

    //console.log(project.data().name)


    useEffect(() => {
        const unsubscribeMyTasks = onSnapshot(firestore.collection(`Projects/${project.id}/Tasks`)
            .where('owner', '==', currentUser.displayName).orderBy('completed'), (querySnapshot) => {

                /* setTest(querySnapshot.data().text)
                console.log(querySnapshot.data().test) */
                const tasksArray = []
                querySnapshot.forEach((doc) => {
                    tasksArray.push(doc)
                })
                setMyTasks(tasksArray)

            })

        const unsubscribeOtherTasks = onSnapshot(firestore.collection(`Projects/${project.id}/Tasks`)
            .where('owner', '!=', currentUser.displayName).limit(25).orderBy('owner').orderBy('completed'), (querySnapshot) => {

                /* setTest(querySnapshot.data().text)
                console.log(querySnapshot.data().test) */
                const tasksArray = []
                querySnapshot.forEach((doc) => {
                    tasksArray.push(doc)
                })
                setOtherTasks(tasksArray)

            })

        return () => {
            unsubscribeMyTasks()
            unsubscribeOtherTasks()
        };
    }, []);

    function addTask(e) {
        e.preventDefault()
        //get value of field
        const task = taskTextRef.current.value
        const meAsOwner = taskCBRef.current.checked

        const data = {
            text: task,
            completed: false,
            owner: 'none',
            projectID: project.id,
            project: project.data().name
        }

        if (meAsOwner) {
            data.owner = currentUser.displayName
        }

        //add step to task subcollection
        todoOperations(ACTIONS.ADD_TODO, data)
        taskTextRef.current.value = ""
    }

    return (
        <div>
            <div className='component'>
                <form className='add-task-form' onSubmit={addTask}>

                    <input className='task-input' ref={taskTextRef} placeholder='+ Add task' type="text" />
                    <div>

                        <p>For me</p>
                        <input ref={taskCBRef} defaultChecked={true} type="checkbox" />
                    </div>
                </form>
                <div className='task-columns'>
                    <div className='unfinished-tasks'>
                        <p className='headline'>My tasks</p>

                        <div>
                            {myTasks.map(task => {
                                return <Task key={task.id} task={task}></Task>
                            })}
                        </div>
                    </div>

                    <div className='finished-tasks'>
                        <p className='headline'>Other tasks</p>
                        <div>
                            {otherTasks.map(task => {
                                return <Task key={task.id} task={task}></Task>
                            })}
                        </div>
                    </div>
                </div>

            </div>






        </div>
    );
}

export default Todo;
