import React, { useState, useEffect, useRef } from 'react';
import './ClientInfoComponent.css'
import editIcon from '../../../Icons/Edit.svg'
import closeIcon from '../../../Icons/Close.svg'
import { clientOperations, ACTIONS } from '../../../DatabaseOperations';

const Clientinfocomponent = ({ client }) => {

    const [firstcontact, setFirstContact] = useState();
    const [lastcontact, setLastContact] = useState();

    const [clientEditMode, setClientEditMode] = useState(false)


    // refs
    const nameRef = useRef()
    const contactRef = useRef()
    const cityRef = useRef()
    const adressRef = useRef()
    const zipcodeRef = useRef()
    const industryRef = useRef()
    const firstcontactRef = useRef()
    const lastcontactRef = useRef()
    const initialcontactRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const usualchannelRef = useRef()
    const notesRef = useRef()



    // turn timestamps into dates
    useEffect(() => {
        try {
            setFirstContact(client.data().firstcontact.toDate().toDateString())
            setLastContact(client.data().lastcontact.toDate().toDateString())
        } catch {

        }

    }, [client]);

    // toggle edit mode
    function toggleEditMode() {
        setClientEditMode(!clientEditMode)
    }

    // submitchanges
    function handleSubmit(e) {
        e.preventDefault()

        //get values of form
        const data = {
            name: nameRef.current.value,
            contact: contactRef.current.value,
            location: {
                city: cityRef.current.value,
                adress: adressRef.current.value,
                zipcode: zipcodeRef.current.value,
            },
            industry: industryRef.current.value,
            initialcontact: initialcontactRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            usualchannel: usualchannelRef.current.value,
            notes: notesRef.current.value
        }
        //edit
        clientOperations(ACTIONS.EDIT_CLIENT, data, client)

        toggleEditMode()
    }

    if (client.data()) {
        return (
            <div className='client-info widget'>
                <div>
                    <img className='project-edit-btn' src={editIcon} onClick={() => toggleEditMode()} alt="Edit" />
                    <h1 className='headline'>{client.data().name}</h1>
                    <div className='info-fields'>
                        <div className='info-field'>
                            <p className='info-label'>Contact:</p>
                            <p>{client.data().contact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>City:</p>
                            <p>{client.data().location.zipcode} </p>
                            <p> {client.data().location.city}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Adress:</p>
                            <p>{client.data().location.adress}</p>

                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Industry:</p>
                            <p>{client.data().industry}</p>
                        </div>

                        <div className='info-field'>
                            <p className='info-label'>First contact:</p>
                            <p>{firstcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Last contact:</p>
                            <p>{lastcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Initial contact:</p>
                            <p>{client.data().initialcontact}</p>
                        </div>
                        <div className='info-field'>
                            <p className='info-label'>Email:</p>
                            <p>{client.data().email}</p>
                        </div>

                        <div className='info-field'>
                            <p className='info-label'>Phone:</p>
                            <p>{client.data().phone}</p>
                        </div>



                        <div className='info-field'>
                            <p className='info-label'>Usual channel:</p>
                            <p>{client.data().usualchannel}</p>
                        </div>


                    </div>
                    <div className='notes'>
                        <p className='notes-headline'>Notes:</p>
                        <p className='notes-text'>{client.data().notes}</p>
                    </div>
                </div>


                {clientEditMode && <div className='client-edit-window'>
                    <img className='close-edit-window-btn' src={closeIcon} alt="delete" onClick={() => toggleEditMode()} />
                    <form onSubmit={handleSubmit}>
                        <div className='form-element'>
                            <p>Name</p>
                            <input defaultValue={client.data().name} ref={nameRef} type="text" />
                        </div>
                        <div className='basic-form'>

                            <div className='form-element'>
                                <p>Contact</p>
                                <input defaultValue={client.data().contact} ref={contactRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>City</p>
                                <input defaultValue={client.data().location.city} ref={cityRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Adress</p>
                                <input defaultValue={client.data().location.adress} ref={adressRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Zip code</p>
                                <input defaultValue={client.data().location.zipcode} ref={zipcodeRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Email</p>
                                <input defaultValue={client.data().email} ref={emailRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Phone</p>
                                <input defaultValue={client.data().phone} ref={phoneRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Industry</p>
                                <input defaultValue={client.data().industry} ref={industryRef} type="text" />
                            </div>
                            <div className='form-element'>
                                <p>Intial contact</p>
                                <select ref={initialcontactRef} className='dropdown' defaultValue={client.data().initialcontact}>
                                    <option value="Harvest">Harvest</option>
                                    <option value="Website">Website</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className='form-element'>
                                <p>Usual channel</p>
                                <select ref={usualchannelRef} className='dropdown' defaultValue={client.data().usualchannel}>
                                    <option value="Phone">Phone</option>
                                    <option value="Email">Email</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                        </div>

                        <div className='form-element notes'>
                            <p>Notes</p>
                            <input defaultValue={client.data().notes} ref={notesRef} type="text" />
                        </div>

                        <button className='input-submit' name="name" value="value" type="submit">Save</button>

                    </form>

                </div>}

            </div>
        );
    }

    return (
        <div>

        </div>
    )


}

export default Clientinfocomponent;
